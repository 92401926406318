import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { KeycloakService } from 'keycloak-angular';
import { AppService } from 'src/app/shared/services/app.service';
import { LocalService } from 'src/app/shared/services/local.service';
import { environment } from 'src/environments/environment';
import { SelectionModel } from '@angular/cdk/collections';
import { MatButtonModule } from '@angular/material/button';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';

@Component({
  template: `
<div style="display: flex; flex-direction: column; height: 80%; margin: 20px;">
  <h1 mat-dialog-title>{{data.title}}</h1>
  <mat-dialog-content style="flex-grow: 1;">
    <p style="text-align: center">{{data.message}}</p>
  </mat-dialog-content>
  <mat-dialog-actions class="mat-dialog-actions" style="justify-content: space-between; margin-top: auto;">
    <button mat-button class="cancel-button" (click)="onCancel()">Cancel</button>
    <button mat-button color="warn" class="confirm-button" (click)="onConfirm()">Confirm</button>
  </mat-dialog-actions>
</div>

  `,
  styles: [`
    .mat-dialog-actions {
      display: flex;
      justify-content: space-between;
    }
    
    .cancel-button {
      margin-right: auto;
    }
    
    .confirm-button {
      margin-left: auto;
    }
  `]
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string }
  ) { }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {

  constructor(
    private breakpointObserver: BreakpointObserver,
    private snackBar: MatSnackBar,
    private readonly appService: AppService,
    private readonly localService: LocalService,
    private readonly keycloakService: KeycloakService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog
  ) { }

  scripts = []
  selectedTestScript: string = "";
  testData = [];
  isResourcesActive = true;
  isTestRunning = true;
  parameters = this.getDefaultJson();
  isResourcesActiveToogle = false;
  dryRun = environment.dryRun;
  dashboardUrl: SafeResourceUrl;
  selectedOrg = undefined;
  selection = new SelectionModel<any>(true, []);

  page = 0;
  displayedColumns: string[] = ['position', 'name', 'testplan', 'scriptname', 'virtualusers', 'rampup', 'startedat', 'duration', 'parameters', 'status', 'actions'];
  dataSource = new MatTableDataSource<any>();

  private refreshIntervalId: any;
  isMobile: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;


  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
        if (this.isMobile) {
          // Define the columns that should be visible on mobile
          this.displayedColumns = ['name', 'scriptname', 'testplan', 'virtualusers', 'rampup', 'startedat', 'duration', 'parameters', 'status',];
        } else {
          // Define the full set of columns for larger screens
          this.displayedColumns = ['position', 'name', 'scriptname', 'testplan', 'virtualusers', 'rampup', 'startedat', 'duration', 'parameters', 'status', 'actions'];
        }
      });
    this.refresh();
    this.startRefreshInterval();
  }

  startRefreshInterval(): void {
    this.refreshIntervalId = setInterval(() => {
      this.refresh();
    }, 15000);
  }

  toggleSelection(execution: any) {
    this.selection.toggle(execution);
  }

  private async toastr(text) {
    this.snackBar.open(text, 'Close', {
      duration: 5000
    });
  }

  refresh() {
    this.keycloakService.loadUserProfile().then(profile => {
      this.selectedOrg = this.localService.getData(profile.id);
      if (this.page == 0) {
        this.refreshTestRun();
        this.refreshExecutions();
      } else if (this.page == 1) {
        this.refreshTestScripts();
        this.refreshTestData();
        this.refreshResources();
        this.refreshTestRun();
      }
    })
  }

  notElegibleForDeepcomparing() {
    return this.selection.selected.length != 2;
  }

  notElegibleForMulticomparing() {
    return this.selection.selected.length < 2 || this.selection.selected.length > 5;
  }

  // Supondo que o _id seja o identificador exclusivo para comparação
  refreshExecutions() {
    this.appService.getExecutions(this.selectedOrg).subscribe((r) => {
      r.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      const selectedIds = this.selection.selected.map((item) => item._id);
      this.dataSource.data = r;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'startedat': return new Date(item.start_time || 0);
          case 'status': return item.status;
          default: return item[property];
        }
      };
      const itemsToSelect = r.filter(item => selectedIds.includes(item._id));
      this.selection.clear();
      this.selection.select(...itemsToSelect);
    }, e => this.errorFound(e));
  }

  showDashboard(execution) {
    this.appService.getDashboard(this.selectedOrg, execution.ltk_id).subscribe((r) => {
      this.dashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(r.url)
      this.setPage(2);
      this.refresh()
    })
  }

  setPage(newPage) {
    this.page = newPage;
  }
  getSelectedExecutionIds() {
    let executionIds = "";
    for (let i = 0; i < this.selection.selected.length; i++) {
      const execution = this.selection.selected[i];
      executionIds = `${executionIds},${execution["ltk_id"]}`
    }
    return executionIds.slice(1);
  }

  showDashboardDeepcomparing() {
    this.appService.getDashboardDeepcomparing(this.selectedOrg, this.getSelectedExecutionIds()).subscribe((r) => {
      this.dashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(r.url)
      this.setPage(2);
      this.refresh()
    })
  }

  showDashboardMulticomparing() {
    this.appService.getDashboardMulticomparing(this.selectedOrg, this.getSelectedExecutionIds()).subscribe((r) => {
      this.dashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(r.url)
      this.setPage(2);
      this.refresh()
    })
  }

  newExecution(execution) {
    if (execution != null) {
      this.parameters = this.appService.getJsonFromExecution(execution);
      console.log("scriptname", execution.scriptname);
      this.selectTestScript(execution.scriptname);
    }
    this.setPage(1);
    this.refresh()
  }

  openExecutionList() {
    this.setPage(0);
  }

  refreshTestScripts() {
    this.appService.getTestScripts(this.selectedOrg).subscribe((r) => { this.scripts = r.data }, e => this.errorFound(e));
  }

  refreshTestData() {
    if (this.selectedTestScript) {
      this.appService.getTestData(this.selectedOrg, this.selectedTestScript).subscribe((r) => { this.testData = r.data }, e => this.errorFound(e));
    }
  }

  refreshResources() {
    if (this.dryRun) {
      this.isResourcesActive = environment.mockAvailability;
      this.isResourcesActiveToogle = environment.mockAvailability;
    } else {
      this.appService.isResourcesActive(this.selectedOrg).subscribe((r) => { this.isResourcesActive = r.data; this.isResourcesActiveToogle = r.data; }, e => this.errorFound(e));
    }
  }

  refreshTestRun() {
    if (this.dryRun) {
      this.isTestRunning = environment.mockIsTestRunning;
    } else {
      this.appService.isTestRunning(this.selectedOrg).subscribe((r) => { this.isTestRunning = r.data }, e => this.errorFound(e));
    }
  }

  uploadTestScript(event) {
    const file: File = event.target.files[0];
    if (file) {
      this.appService.uploadTestScript(this.selectedOrg, file).subscribe(
        (r) => {
          event.target.value = '';
          this.refreshTestScripts();
        },
        (e) => {
          event.target.value = '';
          this.errorFound(e);
        });
    } else {
      event.target.value = '';
      this.toastr("File not found.");
    }
  }

  uploadTestData(event) {
    const file: File = event.target.files[0];
    if (file) {
      this.appService.uploadTestData(this.selectedOrg, file, this.selectedTestScript).subscribe(
        (r) => {
          event.target.value = '';
          this.refreshTestData();
        },
        (e) => {
          event.target.value = '';
          this.errorFound(e);
        });
    } else {
      event.target.value = '';
      this.toastr("File not found.");
    }
  }

  deleteTestScript(script) {
    if (this.dryRun) {
      this.toastr("Feature disabled!");
    } else {
      this.appService.deleteTestScript(this.selectedOrg, script).subscribe((r) => {
        this.refreshTestScripts()
        this.toastr("Script successfully removed");
      }, e => this.errorFound(e));
    }
  }

  deleteExecution(execution) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      height: '200px',
      data: { title: "Deletion", message: 'Confirm delete?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.appService.deleteExecution(this.selectedOrg, execution).subscribe(() => {
          this.refresh();
        });
      }
    });
  }

  purgeExecution(execution) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      height: '200px',
      data: { title: "Purge consolidated data", message: 'Confirm purge?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.appService.purgeExecution(this.selectedOrg, execution).subscribe(() => {
          this.refresh();
        });
      }
    });

  }

  selectTestScript(event) {
    this.selectedTestScript = event;
    if (this.selectedTestScript) {
      this.refreshTestData();
      this.updateParametersJson();
    }
  }

  restoreDefaultJson() {
    if (this.isTestRunning) {
      this.toastr("Wait for the test to complete before performing this operation");
    } else {
      const defaultJson = this.getDefaultJson();
      // bugfix
      this.localService.removeData("parameters");
      this.localService.removeData("aditionalParameters");
      //
      this.parameters.virtualUsers = defaultJson.virtualUsers;
      this.parameters.rampUp = defaultJson.rampUp;
      this.parameters.duration = defaultJson.duration;
      if (!!defaultJson && !!defaultJson.aditionalParameters) {
        defaultJson.additionalParameters = defaultJson.aditionalParameters;
      }
      if (!!defaultJson.additionalParameters && defaultJson.additionalParameters.length > 0) {
        for (const defaultParameter of defaultJson.additionalParameters) {
          let parameterNotFound = true;
          for (const additionalParameter of this.parameters.additionalParameters) {
            if (defaultParameter.name == additionalParameter.name) {
              additionalParameter.value = defaultParameter.value;
              parameterNotFound = false;
              break;
            }
          }
          if (parameterNotFound) {
            this.parameters.additionalParameters.push(defaultParameter);
          }
        }
        const temporaryList = JSON.parse(JSON.stringify(this.parameters.additionalParameters))
        for (const additionalParameter of temporaryList) {
          let parameterNotFound = true;
          for (const defaultParameter of defaultJson.additionalParameters) {
            if (defaultParameter.name == additionalParameter.name) {
              parameterNotFound = false;
              break;
            }
          }
          if (parameterNotFound) {
            const index = this.parameters.additionalParameters.map(e => e.name).indexOf(additionalParameter.name);
            if (index >= 0) {
              this.parameters.additionalParameters.splice(index, 1);
            }
          }
        }
      }
      this.toastr("Parameters successfully restored");
    }
  }

  updateParametersJson() {
    let newParameters = JSON.parse(JSON.stringify(this.parameters));
    newParameters.scriptName = this.selectedTestScript;
    this.parameters = newParameters;
  }

  deleteTestData(data) {
    if (this.dryRun) {
      this.toastr("Feature disabled!");
    } else {
      this.appService.deleteTestData(this.selectedOrg, this.selectedTestScript, data).subscribe((r) => {
        this.refreshTestData();
        this.toastr("TestData successfully removed");
      }, e => this.errorFound(e));
    }
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.toastr("Copied");
  }

  changeResourcesAvailability(value) {
    if (value) {
      this.turnOnResources();
    } else {
      this.turnOffResources();
    }
  }

  turnOnResources() {
    if (this.dryRun) {
      this.toastr("Feature disabled!");
    } else {
      this.appService.startEnvironment(this.selectedOrg).subscribe((r) => {
        this.delay(this.refreshResources(), 5000)
        this.toastr("Resources turned on successfully");
      }, e => this.errorFound(e));
    }
  }

  turnOffResources() {
    if (this.dryRun) {
      this.toastr("Feature disabled!");
    } else {
      this.appService.stopEnvironment(this.selectedOrg).subscribe((r) => {
        this.delay(this.refreshResources(), 5000);
        this.toastr("Resources turned off successfully");
      }, e => this.errorFound(e));
    }
  }

  delay(fn: any, ms: number) {
    return new Promise(resolve => setTimeout(fn, ms));
  }

  getDefaultJson() {
    return this.appService.getDefaultJson(this.selectedTestScript);
  }

  addAdditionalParameter() {
    this.parameters.additionalParameters.push({ name: "VAR_NAME", value: "VAR_VALUE" });
  }

  deleteAdditionalParameter(item) {
    const index = this.parameters.additionalParameters.indexOf(item);
    if (index > -1) {
      this.parameters.additionalParameters.splice(index, 1);
    }
  }

  startTest() {
    if (this.selectedTestScript) {
      // if (this.selectedTestScript && this.isResourcesActive) {
      this.localService.saveData(this.selectedTestScript, this.parameters);
      if (this.dryRun) {
        this.toastr("Feature disabled!");
      } else {
        this.appService.runTest(this.selectedOrg, this.parameters).subscribe(
          (r) => {
            this.openExecutionList()
            this.delay(this.refresh(), 5000)
            this.toastr("Test started successfully");
          }, e => this.errorFound(e));
      }
    } else {
      this.toastr("Resources must be active and Test Script must be selected");
    }
  }

  stopTest() {
    if (this.dryRun) {
      this.toastr("Feature disabled!");
    } else {
      this.appService.stopTest(this.selectedOrg).subscribe((r) => {
        this.delay(this.refresh(), 5000);
        this.toastr("Test stopped successfully");
      }, e => this.errorFound(e));
    }
  }

  errorFound(e) {
    this.toastr("Failure. Contact an administrator");
    console.error(e)
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }
}
