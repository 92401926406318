import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  purgeExecution(org: any, execution: any) {
    return this.http.get<any>(`${this.APP_SVC}/${org}/executions/${execution["ltk_id"]}/purge`);
  }
  deleteExecution(org: string, execution: any) {
    return this.http.delete<any>(`${this.APP_SVC}/${org}/executions/${execution["ltk_id"]}`);
  }

  private APP_SVC = '/svc-loadtests/v1';

  constructor(private http: HttpClient, private localStore: LocalService) { }

  private defaultJson = {
    "scriptName": "",
    "virtualUsers": 5,
    "duration": 30,
    "rampUp": 5,
    "additionalParameters": [
      {
        "name": "TEST_NAME",
        "value": "TEST_001"
      },
      {
        "name": "INPUT_EXAMPLE",
        "value": "INPUT_VALUE"
      }
    ]
  }

  getDefaultJson(scriptName: string) {
    const cachedJson = this.localStore.getData(scriptName);
    const baseJson = this.defaultJson;
    baseJson.scriptName = scriptName || "";
    console.log("loaded", cachedJson || baseJson)
    return cachedJson || baseJson;
  }

  getJsonFromExecution(execution) {
    console.log("execution", execution)
    const baseJson = this.defaultJson;
    baseJson.scriptName = execution.scriptname || "";
    const result = baseJson;
    result.virtualUsers = execution.virtualusers
    result.duration = execution.duration
    result.rampUp = execution.rampup
    result.additionalParameters = execution.parameters
    console.log("result", result);
    return result;
  }

  getExecutions(org: any) {
    return this.http.get<any>(`${this.APP_SVC}/${org}/executions`, { headers: { skipLoading: 'true' } });
  }

  getDashboard(org: any, executionId: any): Observable<any> {
    return this.http.get<any>(`${this.APP_SVC}/${org}/dashboard/${executionId}`);
  }

  getDashboardMulticomparing(org: string, executionIds: string) {
    return this.http.get<any>(`${this.APP_SVC}/${org}/dashboard/multi/${executionIds}`);
  }
  getDashboardDeepcomparing(org: string, executionIds: string) {
    return this.http.get<any>(`${this.APP_SVC}/${org}/dashboard/deep/${executionIds}`);
  }

  getTestScripts(org): Observable<any> {
    return this.http.get<any>(`${this.APP_SVC}/${org}/scripts`, { headers: { skipLoading: 'true' } });
  }

  uploadTestScript(org, script: File): Observable<any> {
    const formData = new FormData();
    formData.append("file", script);
    return this.http.post<any>(`${this.APP_SVC}/${org}/scripts`, formData);
  }

  deleteTestScript(org, scriptName): Observable<any> {
    const deleteTestscriptDto = { scriptName: scriptName };
    return this.http.delete<any>(`${this.APP_SVC}/${org}/scripts`, { body: deleteTestscriptDto });
  }

  getTestData(org, scriptName): Observable<any> {
    const getTestDataDto = { scriptName: scriptName };
    return this.http.post<any>(`${this.APP_SVC}/${org}/test-data`, getTestDataDto, { headers: { skipLoading: 'true' } });
  }

  splitTestData(org, dto): Observable<any> {
    return this.http.post<any>(`${this.APP_SVC}/${org}/test-data/split`, dto);
  }

  uploadTestData(org, testData: File, scriptName: string): Observable<any> {
    const formData = new FormData();
    formData.append("file", testData);
    return this.http.post<any>(`${this.APP_SVC}/${org}/test-data/upload/${scriptName}`, formData);
  }

  deleteTestData(org, scriptName, testData): Observable<any> {
    const deleteTestscriptDto = { scriptName: scriptName, testData: testData };
    return this.http.delete<any>(`${this.APP_SVC}/${org}/test-data`, { body: deleteTestscriptDto });
  }

  runTest(org, dto): Observable<any> {
    this.localStore.saveData(dto.scriptName, dto);
    return this.http.post<any>(`${this.APP_SVC}/${org}/start-test`, dto);
  }

  stopTest(org): Observable<any> {
    return this.http.get<any>(`${this.APP_SVC}/${org}/stop-test`);
  }

  startEnvironment(org): Observable<any> {
    return this.http.get<any>(`${this.APP_SVC}/${org}/start-namespace`);
  }

  stopEnvironment(org): Observable<any> {
    return this.http.get<any>(`${this.APP_SVC}/${org}/stop-namespace`);
  }

  isResourcesActive(org): Observable<any> {
    return this.http.get<any>(`${this.APP_SVC}/${org}/is-resources-active`, { headers: { skipLoading: 'true' } });
  }

  isTestRunning(org): Observable<any> {
    return this.http.get<any>(`${this.APP_SVC}/${org}/is-test-running`, { headers: { skipLoading: 'true' } });
  }

}
